import MenuCard from "../MenuCard/MenuCard";

const MenuTitlesCard = ({ size, text, onClick, isActive, dishes = [] }) => {
  const howBig = size === "big" ? "col-span-2" : "col-span-2 lg:col-span-1";

  return (
    <>
      <div
        className={`${howBig} flex justify-center items-center py-8 px-3 bg-dark text-light uppercase font-bold text-xl tracking-widest cursor-pointer mx-[5vw] border-b-[1px] border-light`}
        onClick={onClick}
        role="button" // Accessibility: Make the div behave like a button
        tabIndex={0} // Accessibility: Allow keyboard navigation
      >
        {text}
      </div>

      {/* Conditionally display filtered dishes if isActive is true */}
      {isActive && (
        <div>
          {dishes.length > 0 ? (
            dishes.map((dish, index) => (
              <div className="mt-8">
                <MenuCard
                  key={index}
                  name={dish.name}
                  description={dish.description}
                  price={dish.price}
                  category={dish.category}
                  isLast={index === dishes.length - 1}
                  dprice={dish.dprice}
                  home={dish.menuType}
                  baigan={dish.baigan}
                  allergies={dish.allergies}
                />
              </div>
            ))
          ) : (
            <p className="my-8 text-dark">Aucun plat disponible</p>
          )}
        </div>
      )}
    </>
  );
};

export default MenuTitlesCard;
