import React from "react";
import "./Header.css";
import { Images } from "../../assets";
import Carousel from "../Carousel/Carousel";

const Header = () => {
  return (
    <header id="header" className="pt-8 flex flex-col px-5">
      <a href="/" className="z-10">
        <img
          className="logo w-[8em] sm:w-[10em] sm:mb-[5vh]"
          src={Images.MAIN_LOGO}
          alt="Logo Cavallo Bianco"
        />
      </a>

      <section className="uppercase font-bold tracking-[0.3em]">
        {/* Curved title svg */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 800 450"
          preserveAspectRatio="xMidYMid meet"
          className="w-[90vw] md:w-[50vw] mx-auto sm:mt-[-10vh] md:mt-[-15vh] lg:mt-[-20vh] xl:mt-[-25vh] lg:-mb-[2rem] xl:-mb-[3rem]"
        >
          <path
            id="curvePath"
            d="M 0 450 Q 400 150 800 450"
            fill="transparent"
            stroke="none"
          />

          <text fontSize="5rem" className="font-title fill-white">
            <textPath href="#curvePath" startOffset="50%" textAnchor="middle">
              Cavallo Bianco
            </textPath>
          </text>
        </svg>

        <div className="flex flex-col items-center gap-5 lg:gap-10">
          <h2 className="text-sm md:text-base lg:text-xl xl:text-2xl">
            Restaurant Pizzeria
          </h2>
          <h2 className="text-sm md:text-base lg:text-xl xl:text-2xl">
            Pl. Chauderon 24, 1003 Lausanne
          </h2>
        </div>
      </section>

      {/* CHANGE RESERVATION LINK */}
      <a
        href="tel:+41216250970"
        className="reserve-button uppercase font-bold tracking-0.3em mt-[1em] mb-[2em] w-3/5 cursor-pointer bg-dark text-light border-2 border-light rounded-full sm:w-1/3 mt-[2em] md:w-2/5 mt-[3em] lg:w-1/5 lg:mt-[7em] mb-[4em] hover:brightness-75 active:brightness-75 transition"
      >
        Réserver
      </a>

      <div className="w-full">
        <Carousel />
      </div>
    </header>
  );
};

export default Header;
