import axios from "axios";  // Importing the axios library for making HTTP requests

// Retrieving the token from localStorage
const token = localStorage.getItem("token");

// Creating an axios instance with a custom configuration
const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}`,  // Setting the base URL for API requests from an environment variable
    headers: {
        "Authorization": `Bearer ${token}`  // Adding the Authorization header with the token from localStorage
    }
});

// Exporting the axios instance for use in other parts of the application
export default axiosInstance;
