import axios from "axios";
import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import axiosInstance from "../../api";

const Chart = () => {
  const [menuItems, setMenuItems] = useState([]); // State to store menu items
  const [chartData, setChartData] = useState([ // State to store chart data for menu categories
    { title: "BreakFast", value: 0, color: "#FF6384" },
    { title: "Lunch", value: 0, color: "#36A2EB" },
    { title: "Dinner", value: 0, color: "#FFCE56" },
  ]);
  
  const [menuTime, setMenuTime] = useState([]); // State to store the list of menu categories (e.g., breakfast, lunch, dinner)
  
  // Function to fetch menu categories from the API
  const fetchMenuTime = async () => {
    try {
      const response = await axiosInstance.get(`/api/category/get-category`); // Fetch categories from the API
      setMenuTime(response.data.data); // Update state with the fetched menu categories
    } catch (error) {
      console.error("Error fetching suggestions:", error); // Log error if the request fails
    } finally {
      // Any cleanup or final actions can be placed here if necessary
    }
  };
  
  // useEffect hook to call fetchMenuTime when the component mounts
  useEffect(() => {
    fetchMenuTime(); // Call fetchMenuTime to load menu categories
  }, []); // Empty dependency array to run once when the component mounts
  
  // Function to get the current date formatted as YYYY-MM-DD
  const getFormattedDate = () => {
    const date = new Date(); // Create a new Date object for the current date
    const year = date.getFullYear(); // Get the full year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month (1-based, padded to 2 digits)
    const day = date.getDate().toString().padStart(2, "0"); // Get the day (padded to 2 digits)
    return `${year}-${month}-${day}`; // Return the formatted date string
  };
  
  // Function to generate a random color in HEX format
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF"; // Possible characters for the color code
    let color = "#"; // Start with the '#' symbol
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]; // Randomly select characters for the color code
    }
    return color; // Return the generated color
  };
  
  // Function to fetch today's menu items from the API
  const fetchTodayMenu = async () => {
    try {
      const todayDate = getFormattedDate(); // Get today's date in the required format
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/menu/get-menu` // Fetch menu data from the API
      );
      setMenuItems(response.data); // Update the menu items state with the fetched data
      if (response && response.data) { // If data is available in the response
        const menuCounts = {}; // Object to store the count of each menu category
        response.data.forEach((item) => {
          const menuType = item.category; // Get the category of the menu item
          if (menuCounts[menuType]) {
            menuCounts[menuType]++; // Increment the count if the category already exists
          } else {
            menuCounts[menuType] = 1; // Initialize count to 1 for a new category
          }
        });
        const updatedChartData = menuTime.map((menu) => { // Map through the menu categories to update chart data
          const menuType = menu.name; // Get the name of the menu category
          return {
            title: menuType, // Set the title as the category name
            value: menuCounts[menuType] || 0, // Set the count for this category (default to 0 if no items)
            color: getRandomColor(), // Assign a random color for this category
          };
        });
  
        setChartData(updatedChartData); // Update the chart data with the newly calculated values
      } else {
        console.warn("No meal data found for today."); // Log a warning if no data was found
        setChartData([]); // Set chart data to an empty array
      }
    } catch (error) {
      console.error("Error fetching today's menu:", error); // Log error if the request fails
    }
  };
  
  // useEffect hook to fetch today's menu whenever the menuTime state changes
  useEffect(() => {
    fetchTodayMenu(); // Call fetchTodayMenu to load menu items
  }, [menuTime]); // Dependency array ensures it runs whenever menuTime changes
  
  // Calculate the total number of menu items across all categories
  const totalMenuItems = chartData.reduce((acc, entry) => acc + entry.value, 0); // Sum up the values of all categories to get the total count
  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        color: "#333",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        border: "1px solid #ddd",
        width: "90%",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h3 style={{ marginBottom: "20px" }}>Today Meal Distribution</h3>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <h1>Total Menu: {totalMenuItems}</h1>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          {chartData.every((entry) => entry.value === 0) ? (
            <p style={{ marginTop: "20px", color: "#999" }}>
              No data available for today.
            </p>
          ) : (
            <PieChart
              data={chartData}
              radius={40}
              lineWidth={15}
              style={{
                height: "200px",
                width: "200px",
                margin: "auto",
              }}
              //  label={() => `${totalMenuItems}`} // Show total menu count in the center
              labelStyle={{
                fontSize: "16px", // Adjust the font size for small screens
                fontWeight: "bold",
                fill: "#333",
              }}
              animate
            />
          )}
        </div>

        <div
          style={{
            flex: 1,
            marginLeft: "20px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap", // Ensures items wrap if content overflows
            alignItems: "center",
            justifyContent: "flex-start", // Aligns items to the left
            gap: "10px", // Adds spacing between items
          }}
        >
          {chartData.map((data, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: data.color,
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              ></div>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                {data.title}: {data.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Chart;
