import React from "react"; // Importing React
import { Link } from "react-router-dom"; // Importing Link component for navigation

const NotFoundPage = () => { // NotFoundPage component definition
  
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      {/* Main container with grid layout to center content */}
      <div className="text-center"> 
        {/* Text container with centered content */}
        <p className="text-5xl font-semibold text-indigo-600">404</p>
        {/* Display the "404" error code in large font and indigo color */}
        
        <h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
          Page not found
        </h1>
        {/* Display the "Page not found" message in large font with a gray color */}
        
        <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        {/* Display a message explaining that the page couldn't be found */}
        
        <div className="mt-10 flex items-center justify-center gap-x-6">
          {/* Button container with spacing between items */}
          
          <Link
            to="/"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
          {/* Link to the home page with a button style */}
          
          <Link to="/" className="text-sm font-semibold text-gray-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </Link>
          {/* Link to contact support page (currently redirects to home) */}
          
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage; // Export the NotFoundPage component
