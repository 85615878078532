import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Sidebar/Sidebar";
import { FaTrash, FaArrowUp, FaArrowDown } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosInstance from "../api";

const UserTableHead = ({ onSort, sortOrder }) => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-300 dark:text-black-400">
      <tr>
        <th
          scope="col"
          className="px-6 py-3 cursor-pointer flex items-center"
          onClick={onSort}
        >
          Name
          {sortOrder === "asc" ? (
            <FaArrowUp className="ml-2" />
          ) : (
            <FaArrowDown className="ml-2" />
          )}
        </th>
        <th scope="col" className="px-6 py-3">
          Email
        </th>
        <th scope="col" className="px-6 py-3">
          Actions
        </th>
      </tr>
    </thead>
  );
};

const UserList = () => {
  const navigate = useNavigate(); // Initialize navigation hook for redirecting

  // Function to validate the token stored in localStorage
  const fetchDataValid = async () => {
    const token = localStorage.getItem("token"); // Retrieve token from localStorage

    if (!token) {
      // If token doesn't exist, navigate to login page
      navigate("/login");
      return; // Exit the function if no token
    }

    try {
      const response = await axios.post(
        // Make POST request to validate the token
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`, // Server endpoint to validate token
        {}, // Empty body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in request headers
          },
        }
      );
      if (response.data.isValid) {
        // If token is valid
        if (response.data.isAdmin) {
          // If the user is an admin, do nothing (stay on the page)
          return;
        } else {
          navigate("/dashboard"); // If the user is not an admin, redirect to the dashboard
        }
      } else {
        navigate("/login"); // If token is invalid, redirect to login page
      }
    } catch (error) {
      console.error("Error during token validation:", error); // Log error if the request fails
      navigate("/login"); // Redirect to login if there was an error
    }
  };

  // UseEffect to call the token validation function when the component mounts
  useEffect(() => {
    fetchDataValid(); // Call the validation function
  }, []); // Empty dependency array to run once when the component mounts

  const [users, setUsers] = useState([]); // State to store user data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [currentPage, setCurrentPage] = useState(1); // State to track current page for pagination
  const [sortOrder, setSortOrder] = useState("asc"); // State to manage the sort order
  const [searchQuery, setSearchQuery] = useState(""); // State to handle the search query
  const itemsPerPage = 12; // Number of items per page for pagination

  // Function to fetch users from the server
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(`/api/auth/get-user`); // Fetch user data
      const sortedUsers = response.data.sort(
        (
          a,
          b // Sort users by name
        ) => a.name.localeCompare(b.name)
      );
      setUsers(sortedUsers); // Update state with sorted user data
    } catch (error) {
      console.error("Error fetching users:", error); // Log error if fetch fails
    } finally {
      setLoading(false); // Set loading to false once the request is complete
    }
  };

  // Function to delete a user
  const deleteUser = async (userId) => {
    try {
      await axiosInstance.delete(`/api/auth/delete-user/${userId}`); // Make delete request to server
      setUsers(users.filter((user) => user._id !== userId)); // Remove deleted user from the state
      toast.success("User Deleted Successfully"); // Show success message
    } catch (error) {
      toast.error("Internal Server Error"); // Show error message on failure
      console.error("Error deleting user:", error); // Log error
    }
  };

  // UseEffect to fetch users when the component mounts
  useEffect(() => {
    fetchUsers(); // Call fetchUsers function
  }, []); // Empty dependency array to run once when the component mounts

  // Function to handle sorting of user data
  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc"; // Toggle sort order
    setSortOrder(newSortOrder); // Update the sort order state

    const sortedUsers = [...users].sort((a, b) => {
      // Create a copy of the users array and sort it
      if (newSortOrder === "asc") {
        return a.name.localeCompare(b.name); // Sort in ascending order by name
      } else {
        return b.name.localeCompare(a.name); // Sort in descending order by name
      }
    });

    setUsers(sortedUsers); // Update the users state with sorted data
  };

  // Pagination logic
  const indexOfLastUser = currentPage * itemsPerPage; // Calculate index of the last user on the current page
  const indexOfFirstUser = indexOfLastUser - itemsPerPage; // Calculate index of the first user on the current page

  const totalPages = Math.ceil(users.length / itemsPerPage); // Calculate total number of pages based on the number of users

  const paginate = (pageNumber) => setCurrentPage(pageNumber); // Update the current page when a new page number is selected

  // Filter the users based on search query (by name or email)
  const filteredData = users.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) || // Check if name matches the search query
      item.email.toLowerCase().includes(searchQuery.toLowerCase()) // Check if email matches the search query
  );

  // Function to hash a string to a number for consistent color generation
  const hashStringToNumber = (str) => {
    let hash = 0; // Initialize hash value
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 8) - hash); // Generate hash from the string characters
    }
    return Math.abs(hash); // Return absolute value of the hash
  };

  // Function to generate a consistent HSL color based on the hash
  const generateFixedColor = (str) => {
    const hash = hashStringToNumber(str); // Get the hash value of the string
    const hue = hash % 360; // Generate hue value between 0 and 360
    const saturation = 70; // Set saturation to 70%
    const lightness = 50; // Set lightness to 50%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Return the generated HSL color
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by name or email..."
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={searchQuery}
              style={{ border: "2px solid black" }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full text-sm text-left text-black-500 dark:text-black-400">
              <UserTableHead onSort={handleSort} sortOrder={sortOrder} />
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3" className="text-center py-3">
                      Loading...
                    </td>
                  </tr>
                ) : (
                  filteredData
                    .slice(indexOfFirstUser, indexOfLastUser)
                    .map((user) => (
                      <tr
                        key={user._id}
                        className="bg-white border-b dark:bg-white-800 dark:border-gray-700"
                      >
                        <td className="px-6 py-3 flex items-center">
                          <div
                            style={{
                              backgroundColor: generateFixedColor(user.name),
                            }}
                            className="w-10 h-10 text-white font-semibold rounded-full flex items-center justify-center"
                          >
                            {user.name.charAt(0).toUpperCase()}
                          </div>
                          <span className="ml-2">{user.name}</span>
                        </td>
                        <td className="px-6 py-3">{user.email}</td>
                        <td className="px-6 py-3">
                          <FaTrash
                            className="h-6 w-6 text-red-500 cursor-pointer"
                            onClick={() => deleteUser(user._id)}
                          />
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l"
          >
            Prev
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-4 py-2 ${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
              } rounded`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserList;
