import axios from "axios"; // Importing axios for making API calls
import React, { useState } from "react"; // Importing React and useState hook
import { useNavigate } from "react-router-dom"; // Importing useNavigate for navigation
import { toast } from "react-toastify"; // Importing toast for displaying notifications
import Loader from "../components/Loader/Loader"; // Importing Loader component for showing loading state
import login12 from "../assets/images/login.jpg"; // Importing login image

const Login = () => {
  const navigate = useNavigate(); // Initialize navigate function for page navigation
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [post, setPost] = useState({ email: "", password: "" }); // State to store email and password input
  const [emailForReset, setEmailForReset] = useState(""); // State to store email for password reset
  const [showModal, setShowModal] = useState(false); // State to toggle the visibility of the password reset modal

  const handleInput = (e) => { // Handle form input change
    setPost({ ...post, [e.target.name]: e.target.value }); // Update the post state with the form input value
  };

  const handleForgotPassword = async (e) => { // Handle forgot password form submission
    e.preventDefault(); // Prevent page reload on form submit
    setLoading(true); // Set loading to true to show loader

    try {
      // Make API call to send the password reset link
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/forgot-password`, // API endpoint for password reset
        { email: emailForReset }, // Send email for reset in request body
        { headers: { "Content-Type": "application/json" } } // Set headers for JSON content type
      );

      setLoading(false); // Set loading to false after the response
      if (response.data.success) { // Check if the response was successful
        toast.success("Password reset link sent to your email!"); // Show success message
        setShowModal(false); // Close the modal
      } else {
        toast.error(response.data.message || "Error sending reset link."); // Show error message if failure
      }
    } catch (error) {
      setLoading(false); // Set loading to false if an error occurs
      toast.error(
        error.response?.data?.message || "Something went wrong. Try again." // Show error message
      );
    }
  };

  const login = async (e) => { // Handle login form submission
    e.preventDefault(); // Prevent page reload on form submit
    setLoading(true); // Set loading to true to show loader
    try {
      const config = { // Configuration for headers
        headers: { "Content-Type": "application/json" },
      };

      // Make API call to log in the user
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/login`, // API endpoint for login
        post, // Send email and password in request body
        config // Set headers for JSON content type
      );
      setLoading(false); // Set loading to false after the response
      if (response.data.success) { // Check if the login was successful
        localStorage.setItem("token", response.data.token); // Store JWT token in localStorage
        localStorage.setItem("admin", response.data.isAdmin); // Store user admin status
        localStorage.setItem("name", response.data.user.name); // Store user name
        toast.success("Login successful!"); // Show success message
        setTimeout(() => {
          navigate("/dashboard"); // Navigate to the dashboard page after 2 seconds
        }, 2000);
      } else {
        toast.error(response.data.message || "Invalid credentials!"); // Show error message if invalid credentials
      }
    } catch (error) {
      setLoading(false); // Set loading to false if an error occurs
      toast.error(
        error.response?.data?.message || "Something went wrong. Please try again." // Show error message
      );
    }
  };

  return (
    <>
      {loading && ( // Conditionally render the loader while loading
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Loader /> {/* Display the loader component */}
        </div>
      )}
      <div className="flex flex-col md:flex-row h-screen bg-white">
        <div className="w-full flex justify-center mt-8 md:hidden">
          <img
            src={login12} // Display login image
            alt="Flowers" // Alt text for the image
            className="w-5/6 sm:w-3/4 md:w-3/4 h-auto rounded-2xl object-cover"
          />
        </div>

        <div className="flex flex-col justify-center items-center md:w-1/2 px-6 md:px-12 mt-4 mx-4 md:mt-0 md:mx-0 max-w-7xl">
          <div className="w-full max-w-md lg:w-4/5 lg:ml-8 bg-[#f4f4f4] p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-6 text-gray-800 mt-4 sm:mt-10 sm:ml-6 lg:w-2/3 lg:ml-8">
              Welcome Back 👋
            </h2>
            <p className="text-gray-600 mb-6 lg:w-4/5 lg:ml-8">
              Sign in to start managing your projects.
            </p>

            <form onSubmit={login} className="lg:w-4/5 lg:ml-8">
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm text-gray-600 mb-1"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-2 border border-gray-400 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                  placeholder="Example@email.com"
                  required
                  name="email"
                  onChange={handleInput} // Update the state when input changes
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm text-gray-600 mb-1"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={handleInput} // Update the state when input changes
                  className="w-full px-4 py-2 border border-gray-400 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                  placeholder="At least 8 characters"
                  required
                />
              </div>
              <div className="flex justify-end items-end mb-4">
                <button
                  type="button"
                  onClick={() => setShowModal(true)} // Show the forgot password modal
                  className="text-blue-500 text-sm"
                >
                  Forgot Password?
                </button>
              </div>
              <button
                type="submit"
                className="w-full bg-black text-white py-2 rounded-md hover:bg-gray-800 transition duration-200 shadow-md"
              >
                Log in
              </button>
            </form>
          </div>
        </div>

        <div className="hidden md:flex md:w-1/2 items-center justify-center p-4 lg:mt-2 lg:mx-4 mr-0 max-w-7xl">
          <img
            src={login12} // Display the login image on larger screens
            alt="Flowers" // Alt text for the image
            className="w-7/8 lg:w-7/8 lg:h-[95vh] h-auto rounded-2xl object-cover"
          />
        </div>
      </div>

      {/* Forgot Password Modal */}
      {showModal && ( // Conditionally render the forgot password modal if showModal is true
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6 rounded-lg shadow-md w-96 transition-all">
            <h2 className="text-lg font-bold text-white mb-4">
              Forgot Password
            </h2>
            <form onSubmit={handleForgotPassword}>
              <label
                htmlFor="reset-email"
                className="block text-sm text-white mb-2"
              >
                Enter your email to receive a password reset link:
              </label>
              <input
                type="email"
                id="reset-email"
                className="w-full px-3 py-2 border rounded-lg mb-4 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                value={emailForReset} // Controlled input for email
                onChange={(e) => setEmailForReset(e.target.value)} // Update email state on input change
                required
              />
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition"
              >
                Send Reset Link
              </button>
            </form>
            <button
              onClick={() => setShowModal(false)} // Close the modal when clicked
              className="mt-4 text-white text-sm"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Login; // Export Login component
