import React, { useEffect, useState } from "react";
import Navbar from "../components/Sidebar/Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./Confirmationmodel";
import axiosInstance from "../api";

const AdminContact = () => {
  // State variables for managing contacts, search query, loading state, pagination, and delete modal
  const [contacts, setContacts] = useState([]); // Store the list of contacts
  const [searchQuery, setSearchQuery] = useState(""); // Store the search query for filtering contacts
  const [loading, setLoading] = useState(false); // Track loading state for data fetching
  const [currentPage, setCurrentPage] = useState(1); // Track the current page for pagination
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Track if the delete confirmation modal is open
  const [itemsPerPage] = useState(10); // Set the number of contacts to display per page
  const navigate = useNavigate(); // Use the navigate hook for navigation

  // Function to validate the token stored in localStorage
  const fetchDataValid = async () => {
    const token = localStorage.getItem("token"); // Retrieve the token from localStorage

    if (!token) {
      // If no token is found, redirect to login page
      navigate("/login");
      return;
    }

    try {
      // Send a request to validate the token
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the authorization header
          },
        }
      );
      if (response.data.isValid) {
        // If the token is valid, do nothing
        return;
      } else {
        // If the token is invalid, redirect to login page
        navigate("/login");
      }
    } catch (error) {
      // Handle errors during token validation
      console.error("Error during token validation:", error); // Log the error
      navigate("/login"); // Redirect to login page in case of an error
    }
  };

  // useEffect hook to validate the token when the component is mounted
  useEffect(() => {
    fetchDataValid(); // Call the token validation function
  }, []); // Empty dependency array ensures the effect runs once on mount

  // Function to fetch the list of contacts from the server
  const fetchContacts = async () => {
    try {
      setLoading(true); // Set loading state to true before making the API request
      const response = await axiosInstance.get("/api/contact/all"); // Get all contacts from the API
      setContacts(response.data.data || []); // Update the contacts state with the fetched data
    } catch (error) {
      // Handle errors during the fetching process
      toast.error("Failed to fetch contacts"); // Show an error toast
      console.error(error); // Log the error to the console
    } finally {
      setLoading(false); // Set loading state to false after the API call completes
    }
  };

  // Function to delete a single contact by ID
  const deleteContact = async (id) => {
    try {
      await axiosInstance.delete(`/api/contact/delete/${id}`); // Send a DELETE request to remove the contact
      toast.success("Contact deleted successfully"); // Show a success toast
      fetchContacts(); // Refresh the list of contacts after deletion
    } catch (error) {
      // Handle errors during the deletion process
      toast.error("Failed to delete contact"); // Show an error toast
      console.error(error); // Log the error to the console
    }
  };

  // Function to delete all contacts
  const deleteAllContacts = async () => {
    try {
      await axiosInstance.delete(`/api/contact/delete-all`); // Send a DELETE request to remove all contacts
      toast.success("All contacts deleted successfully"); // Show a success toast
      setContacts([]); // Clear the contacts state
      setIsDeleteModalOpen(false); // Close the delete confirmation modal
    } catch (error) {
      // Handle errors during the deletion process
      toast.error("Failed to delete all contacts"); // Show an error toast
      console.error(error); // Log the error to the console
    }
  };

  // Function to export the contacts list to an Excel file
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(contacts); // Convert contacts to a worksheet format
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts"); // Append the worksheet to the workbook
    XLSX.writeFile(workbook, "contacts.xlsx"); // Trigger file download with the name "contacts.xlsx"
  };

  // useEffect hook to fetch contacts when the component is mounted
  useEffect(() => {
    fetchContacts(); // Call the function to fetch contacts
  }, []); // Empty dependency array ensures the effect runs once on mount

  // Filter contacts based on the search query
  const filteredContacts = contacts.filter(
    (contact) =>
      contact.email.toLowerCase().includes(searchQuery.toLowerCase()) || // Match email with the search query
      contact.phone.toLowerCase().includes(searchQuery.toLowerCase()) // Match phone number with the search query
  );

  // Pagination logic
  const indexOfLastContact = currentPage * itemsPerPage; // Calculate the index of the last contact on the current page
  const indexOfFirstContact = indexOfLastContact - itemsPerPage; // Calculate the index of the first contact on the current page
  const currentContacts = filteredContacts.slice(
    indexOfFirstContact,
    indexOfLastContact
  ); // Get the contacts to display on the current page

  const pageCount = Math.ceil(filteredContacts.length / itemsPerPage); // Calculate the total number of pages based on the number of filtered contacts

  // Function to handle page changes in pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update the current page state when a new page is selected
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-400 mt-14">
          <div className="flex justify-between items-center mb-4">
            <input
              type="text"
              placeholder="Search by email"
              className="border border-gray-700 rounded-lg p-2 w-1/3"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="space-x-2">
              <button
                className="px-4 py-2 bg-black text-white rounded-md mr-2"
                // onClick={deleteAllContacts}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete All
              </button>
              <button
                className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
                onClick={exportToExcel}
              >
                Download Excel
              </button>
            </div>
          </div>

          {loading ? (
            <p className="text-center">Loading...</p>
          ) : filteredContacts.length === 0 ? (
            <p className="text-center">No contacts found.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-black uppercase bg-gray-100 dark:bg-gray-300 dark:text-black-400">
                  <tr>
                    <th className="px-6 py-3">Email</th>
                    <th className="px-6 py-3">Phone</th>
                    <th className="px-6 py-3">Message</th>
                    <th className="px-6 py-3 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentContacts.map((contact) => (
                    <tr
                      key={contact._id}
                      className="bg-white border-b dark:bg-white-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 text-black">{contact.email}</td>
                      <td className="px-6 py-4 text-black">{contact.phone}</td>
                      <td className="px-6 py-4 text-black">
                        {contact.message}
                      </td>
                      <td className="px-6 py-4 text-center">
                        <button onClick={() => deleteContact(contact._id)}>
                          <FaTrash className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination Controls */}
          <div className="flex justify-center mt-4">
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span className="px-4 py-2">{`${currentPage} / ${pageCount}`}</span>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              disabled={currentPage === pageCount}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={deleteAllContacts}
      />
    </div>
  );
};

export default AdminContact;
