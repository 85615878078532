import React, { useState } from "react";
import { scroller } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../assets";

const BurgerMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (to) => {
    if (location.pathname === "/") {
      // If already on home page, just scroll to the section
      scroller.scrollTo(to, {
        smooth: true,
        duration: 500,
      });
    } else {
      // Navigate to home first, then scroll after load
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(to, {
          smooth: true,
          duration: 500,
        });
      }, 500); // Delay to ensure navigation is complete
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sticky top-0 z-50">
      {/* Burger Icon */}
      <button
        className={`top-10 right-10 z-50 flex flex-col justify-between w-5 h-4 bg-transparent border-none cursor-pointer focus:outline-none md:w-8 md:h-6 ${
          isOpen ? "fixed" : "absolute"
        }`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span
          className={`block w-full h-[2px] transition-transform duration-200 md:h-1 ${
            isOpen
              ? "rotate-45 translate-y-[7px] bg-dark md:translate-y-[10px]"
              : "bg-white"
          }`}
        />
        <span
          className={`block w-full h-[2px] bg-white transition-opacity duration-200 md:h-1 ${
            isOpen ? "opacity-0" : ""
          }`}
        />
        <span
          className={`block w-full h-[2px] transition-transform duration-200 md:h-1 ${
            isOpen
              ? "-rotate-45 -translate-y-[7px] bg-dark md:-translate-y-[10px]"
              : "bg-white"
          }`}
        />
      </button>

      {/* Menu */}
      <nav
        className={`fixed top-0 right-0 h-full bg-light w-64 p-5 shadow-lg transition-transform duration-200 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <ul className="flex flex-col gap-5 mt-[10vh] ml-3 text-left">
          <li>
            <a
              href="/"
              className="flex items-center gap-2 text-dark hover:text-yellow-500 active:text-yellow-500 transition cursor-pointer"
            >
              <img src={Images.HOME} alt="Accueil" className="w-10" />
              <span className="text-lg">Accueil</span>
            </a>
          </li>
          <li>
            <a
              href="/menu"
              className="flex items-center gap-2 text-dark hover:text-yellow-500 active:text-yellow-500 transition cursor-pointer"
            >
              <img src={Images.MENU} alt="Menu" className="w-10" />
              <span className="text-lg">Menu</span>
            </a>
          </li>
          <li>
            <div
              onClick={() => handleClick("about")}
              className="flex items-center gap-2 text-dark hover:text-yellow-500 active:text-yellow-500 transition cursor-pointer"
            >
              <img src={Images.ABOUT} alt="A propos" className="w-10" />
              <span className="text-lg">A propos</span>
            </div>
          </li>
          <li>
            <span
              onClick={() => handleClick("contact")}
              className="flex items-center gap-2 text-dark hover:text-yellow-500 active:text-yellow-500 transition cursor-pointer"
            >
              <img src={Images.CONTACT} alt="Contact" className="w-10" />
              <span className="text-lg">Contactez-nous</span>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default BurgerMenu;
