import React, { useEffect, useState } from "react";
import Navbar from "../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import Modal from "react-modal";
import axiosInstance from "../api";

const date = "12";

const MenuDescription = () => {
  const [menus, setMenus] = useState([]); // Declare state to hold menu data

  const navigate = useNavigate(); // Hook to handle navigation in the app
  const fetchDataValid = async () => {
    // Function to validate the token
    const token = localStorage.getItem("token"); // Get token from local storage

    if (!token) {
      // If token is not found
      navigate("/login"); // Redirect to login page
      return; // Exit the function
    }

    try {
      const response = await axios.post(
        // Make POST request to validate token
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in authorization header
          },
        }
      );
      if (response.data.isValid) {
        // If the token is valid
        return; // Do nothing and return
      } else {
        navigate("/login"); // Redirect to login if token is invalid
      }
    } catch (error) {
      // Catch any error during the request
      console.error("Error during token validation:", error); // Log error
      navigate("/login"); // Redirect to login page if error occurs
    }
  };

  useEffect(() => {
    fetchDataValid(); // Validate token when the component is mounted
  }, []);

  const [isAddMenuModalOpen, setAddMenuModalOpen] = useState(false); // State to manage modal visibility

  const [menuData, setMenuData] = useState({
    // State for menu form data
    name: "",
    price: "",
    description: "",
    menuType: "No",
    dprice: "",
    allergies: "",
    baigan: "",
    category: "",
  });

  const handleChange = (e) => {
    // Handle form field changes
    const { name, value } = e.target; // Get field name and value
    setMenuData((prev) => ({
      // Update the state with the new field value
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    // Handle menu form submission
    e.preventDefault(); // Prevent default form submission
    if (parseFloat(menuData.price) <= 0) {
      // Check if price is greater than zero
      toast.info("Please enter a valid price greater than zero."); // Show toast message
      return; // Exit if the price is invalid
    }
    if (parseFloat(menuData.dprice) > parseFloat(menuData.price)) {
      // Check if discount price is valid
      toast.info("Discount price cannot exceed the menu price."); // Show toast message
      return; // Exit if the discount price is invalid
    }
    try {
      const response = await axiosInstance.post(
        // Send request to add menu
        `/api/menu/add-single`,
        menuData
      );
      fetchMenus(); // Fetch updated menu list
      toast.success("Menu added successfully!"); // Show success message
      setAddMenuModalOpen(false); // Close modal after submission
      setMenuData({
        // Reset form data
        name: "",
        price: "",
        description: "",
        menuType: "",
        dprice: "",
        baigan: "",
        category: "",
      });
    } catch (error) {
      // Handle any errors during the menu submission
      console.error("Error adding menu:", error); // Log error
      toast.error("Failed to add menu. Please try again."); // Show error message
    }
  };

  const fetchMenus = async () => {
    // Function to fetch menu data
    try {
      const response = await axiosInstance.get(`/api/menu/get-menu`); // Send request to get menus
      if (response.data && response.data) {
        // Check if data exists
        setMenus(response.data); // Update menus state with response data
      }
    } catch (err) {
      // Handle errors during fetching menus
      console.error("Failed to fetch menu data:", err); // Log error
    }
  };

  useEffect(() => {
    fetchMenus(); // Fetch menus when component is mounted
  }, []);

  const handleDelete = async (data) => {
    // Function to handle menu deletion
    try {
      await axiosInstance.delete(`/api/menu/delete/${data._id}`); // Send request to delete menu
      toast.success(`${data.name} has been deleted successfully.`); // Show success message
      fetchMenus(); // Fetch updated menu list
    } catch (error) {
      // Handle errors during menu deletion
      console.error("Error deleting menu:", error); // Log error
      toast.error("Failed to delete the menu."); // Show error message
    }
  };

  const [isEditMenuModalOpen, setEditMenuModalOpen] = useState(false); // State to manage edit menu modal visibility
  const [editmenuData, editsetMenuData] = useState({
    // State for editing menu data
    name: "",
    price: "",
    description: "",
    menuType: "",
    dprice: "",
    allergies: "",
    baigan: "",
    category: "",
  });

  const handleEditChange = (event) => {
    // Handle changes in edit menu form
    const { name, value } = event.target; // Get field name and value

    if (name === "dprice" && value !== "") {
      // Ensure the discount price is valid
      const numericValue = Math.max(0, parseFloat(value)); // Ensure non-negative value
      editsetMenuData((prevData) => ({
        // Update edit menu data
        ...prevData,
        [name]: numericValue,
      }));
    } else {
      editsetMenuData((prevData) => ({
        // Update the other field values
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [editValue, setEditValue] = useState({}); // State for the selected menu item to be edited
  const handleEdit = (item) => {
    // Function to handle editing a menu item
    console.log(item); // Log the selected menu item
    setEditValue(item); // Set the selected item for editing
    editsetMenuData(item); // Set the menu data for editing
    setEditMenuModalOpen(true); // Open the edit modal
  };

  const handleEditSubmit = async () => {
    // Function to submit edited menu data
    try {
      if (parseFloat(editmenuData.price) <= 0) {
        // Check if price is valid
        toast.info("Please enter a valid price greater than zero."); // Show info message
        return; // Exit if the price is invalid
      }
      if (parseFloat(editmenuData.dprice) > parseFloat(editmenuData.price)) {
        // Check if discount price is valid
        toast.info("Discount price cannot exceed the main price."); // Show info message
        return; // Exit if the discount price is invalid
      }
      const response = await axiosInstance.put(
        // Send PUT request to update menu data
        `/api/menu/edit/${editValue._id}`,
        editmenuData
      );
      toast.success("Menu updated successfully!"); // Show success message
      fetchMenus(); // Fetch updated menu list
      setEditMenuModalOpen(false); // Close edit modal
    } catch (error) {
      // Handle errors during menu update
      console.error("Error updating menu:", error); // Log error
      toast.error("Failed to update menu!"); // Show error message
    }
  };

  const [addPreviousMenuModalOpen, setAddPreviousMenuModalOpen] =
    useState(false); // State for previous menu modal visibility
  const [formData, setFormData] = useState({
    // State for the previous menu form
    date: "",
    menuType: "new",
  });

  const handleInputChange = (e) => {
    // Handle form changes for previous menu
    const { name, value } = e.target; // Get field name and value
    setFormData({ ...formData, [name]: value }); // Update form data
  };

  const handleSubmitPrevMenu = async (e) => {
    // Handle submitting previous menu data
    e.preventDefault(); // Prevent default form submission
    try {
      const editmenuData = {
        // Create data object for the previous menu
        date: formData.date,
        menuType: formData.menuType,
      };

      const response = await axiosInstance.put(
        // Send PUT request to add previous menu
        `/api/menu/prev/${date}`,
        editmenuData,
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );
      if (response.status == 202); // Check for status 202
      {
        toast.info(response.data.message); // Show response message
      }
      if (response.status == 200) {
        // Check for status 200
        setAddPreviousMenuModalOpen(false); // Close modal
        fetchMenus(); // Fetch updated menu list
        toast.success("Added Successfully"); // Show success message
      }
    } catch (error) {
      // Handle any errors during the request
      console.error("Error:", error); // Log error
      toast.error("Something went wrong"); // Show error message
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // State for current page number
  const itemsPerPage = 10; // Number of items per page
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const handleSearchChange = (e) => {
    // Handle search input change
    setSearchQuery(e.target.value); // Update search query state
  };

  const [category, setCategory] = useState([]); // State to hold category data

  const fetchSuggestions = async () => {
    // Function to fetch category suggestions
    try {
      const response = await axiosInstance.get(`/api/category/get-category`); // Send request to fetch categories
      setCategory(response.data.data); // Update category state with fetched data
    } catch (error) {
      // Handle any errors during fetching categories
      console.error("Error fetching suggestions:", error); // Log error
    } finally {
    }
  };

  useEffect(() => {
    fetchSuggestions(); // Fetch category suggestions when the component is mounted
  }, []);

  const renderTable = (type, data) => {
    // Combine all menu items into a single list and filter based on search query
    const filteredMenu = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Paginate the filtered data
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = filteredMenu.slice(
      startIndex,
      startIndex + itemsPerPage
    );

    return (
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">{type}</h2>
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left text-black-500 dark:text-black-400">
            <thead className="text-xs text-black-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-black-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Name
                </th>
                <th scope="col" className="py-3 px-6">
                  Price
                </th>
                <th scope="col" className="py-3 px-6">
                  Discount Price
                </th>
                <th scope="col" className="py-3 px-6">
                  Category
                </th>
                <th scope="col" className="py-3 px-6">
                  Description
                </th>
                <th scope="col" className="py-3 px-6">
                  Home Made
                </th>
                <th scope="col" className="py-3 px-6">
                  Allergies
                </th>
                <th scope="col" className="py-3 px-6">
                  Vegan
                </th>
                <th scope="col" className="py-3 px-6">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <tr
                    key={item._id}
                    className="bg-white border-b dark:bg-white-800 dark:border-white-700"
                  >
                    <td className="py-4 px-6">{item.name}</td>
                    <td className="py-4 px-6">{item.price}</td>
                    <td className="py-4 px-6">{item.dprice}</td>
                    <td className="py-4 px-6">{item.category}</td>
                    <td className="py-4 px-6">{item.description}</td>
                    <td className="py-4 px-6">{item.menuType}</td>
                    <td className="py-4 px-6">{item.allergies}</td>
                    <td className="py-4 px-6">
                      {item.baigan ? item.baigan : ""}
                    </td>
                    <td className="py-4 px-6 flex gap-2">
                      <FaEdit
                        onClick={() => handleEdit(item)}
                        className="text-blue-600 cursor-pointer hover:text-blue-800"
                        title="Edit"
                        size={20}
                      />
                      <FaTrash
                        onClick={() => handleDelete(item)}
                        className="text-red-600 cursor-pointer hover:text-red-800"
                        title="Delete"
                        size={20}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    <span className="text-gray-500 text-lg font-semibold italic">
                      No data found
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
            className="px-3 py-1 border rounded-l-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-4 py-1 border bg-gray-100">{currentPage}</span>
          <button
            disabled={currentPage * itemsPerPage >= filteredMenu.length}
            onClick={() => setCurrentPage((prev) => prev + 1)}
            className="px-3 py-1 border rounded-r-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const [excel, setExcel] = useState(false); // State to manage whether the download button was clicked

  const download = () => {
    setExcel(true); // Sets the excel state to true when the download button is clicked
  };

  const downloadSampleFile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/menu-download`, // Fetching the sample file from the server
        { responseType: "blob" } // Ensures the response is treated as a binary large object (blob)
      );

      const url = window.URL.createObjectURL(new Blob([response.data])); // Creates a URL for the blob data
      const link = document.createElement("a"); // Creates a link element
      link.href = url; // Sets the link's href to the blob URL
      link.setAttribute("download", "sample-menu-weekly.xlsx"); // Sets the download attribute for the link
      document.body.appendChild(link); // Appends the link to the document
      link.click(); // Simulates a click to trigger the download
      link.remove(); // Removes the link after download

      toast.success("Download Successfully"); // Shows a success message on successful download
      setExcel(false); // Resets the excel state to false after download
    } catch (error) {
      console.error("Error downloading the sample file:", error); // Logs error if download fails
      toast.error("Internal Server Error"); // Displays an error message if download fails
    }
  };

  const [isModalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [selectedFile, setSelectedFile] = useState(null); // State to manage the selected file

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Sets the selected file when the user selects a file
  };

  const handleSubmitWeekLy = async () => {
    if (!selectedFile) {
      toast.info("Please select a file before submitting."); // Displays a message if no file is selected
      return;
    }

    const formData = new FormData(); // Creates a new FormData object to send the file
    formData.append("file", selectedFile); // Appends the selected file to the form data

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/menu/week`, // Sends the file to the server
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }, // Sets the correct content type for file uploads
        }
      );
      toast.success("File uploaded successfully!"); // Shows success message if file upload is successful
    } catch (error) {
      console.error("Error uploading file:", error); // Logs error if file upload fails
      toast.error("Failed to upload file."); // Displays an error message if upload fails
    }

    setModalOpen(false); // Closes the modal after file upload
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14">
          {/* Align buttons in a single line */}

          {excel && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white dark:bg-gray-200 p-6 rounded-lg w-96">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold">
                    Upload Menu as Excel
                  </h2>
                  <button
                    onClick={() => setExcel(false)}
                    className="text-black hover:text-gray-800"
                  >
                    ✕
                  </button>
                </div>
                <p className="text-sm mb-4 text-black">
                  Please upload the menu details as an Excel file. Ensure that
                  the fields are formatted correctly:
                </p>
                <ul className="list-disc list-inside text-sm text-black dark:text-black">
                  <li>
                    <strong>Menu Type:</strong> Type of the menu (Homemade
                    if,Yes.else No).
                  </li>
                  <li>
                    <strong>Name:</strong> Menu item name (required).
                  </li>
                  <li>
                    <strong>Description:</strong> Description of the menu item
                    (required).
                  </li>
                  <li>
                    <strong>Allergies:</strong> Any allergy details (optional).
                  </li>
                  <li>
                    <strong>Category:</strong> Category of the menu item (e.g.,
                    vegetarian, non-vegetarian).
                  </li>
                  <li>
                    <strong>Price:</strong> Price of the menu item .
                  </li>
                  <li>
                    <strong>Discounted Price:</strong> Discounted price of the
                    menu item.
                  </li>
                </ul>
                <button
                  onClick={downloadSampleFile}
                  className="mt-4 w-full px-4 py-2 text-white bg-black rounded-md"
                >
                  Download Sample Excel
                </button>
              </div>
            </div>
          )}

          <div className="w-full p-2 flex flex-row items-center  gap-2">
            <button
              className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-600"
              onClick={() => setAddMenuModalOpen(true)}
            >
              Add Menu
            </button>

            <button
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={download} // Handler for downloading the sample file
            >
              Download Sample File
            </button>

            <button
              className="px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-400"
              onClick={() => setModalOpen(true)} // Handler for uploading the Excel file
            >
              Upload Excel
            </button>
          </div>

          <div className="mt-4 flex justify-center relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search menu..."
              className="px-4 py-2 pl-12 border-4 border-gray-600 rounded-lg shadow-md w-full"
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35m1.1-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>

          <div className="mt-6">{renderTable("All Menus", menus)}</div>
        </div>
      </div>

      {isAddMenuModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-sm sm:max-w-md md:max-w-sm lg:max-w-md xl:max-w-lg p-10 overflow-y-auto max-h-[70vh]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold mb-4">Add Daily Menu</h2>
              {/* Close Icon */}
              <button
                onClick={() => setAddMenuModalOpen(false)}
                className="text-gray-500 hover:text-gray-800 transition-colors duration-200 focus:outline-none"
              >
                <i
                  className="fa fa-times text-xl"
                  style={{ color: "red", fontSize: "30px" }}
                ></i>
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Category
                </label>
                <select
                  name="category"
                  id="category"
                  value={menuData.category}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                  required
                >
                  <option value="" disabled>
                    Select category
                  </option>
                  {category.map((category, index) => (
                    <option key={index} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Menu Name */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Menu Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={menuData.name}
                  onChange={handleChange}
                  placeholder="Enter menu name"
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                  required
                />
              </div>

              {/* Menu Price */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Menu Price
                </label>
                <input
                  type="number"
                  name="price"
                  placeholder="Enter menu price"
                  value={menuData.price}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0);
                    setMenuData((prev) => ({
                      ...prev,
                      price: value,
                    }));
                  }}
                  onBlur={() => {
                    setMenuData((prev) => ({
                      ...prev,
                      price: Math.max(0, parseFloat(prev.price) || 0),
                    }));
                  }}
                  className={`mt-1 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 ${
                    parseFloat(menuData.price) <= 0
                      ? "border-red-500"
                      : "border-black"
                  }`}
                />
                {parseFloat(menuData.price) <= 0 && (
                  <p className="text-red-500 text-sm">
                    Price must be a positive value greater than zero.
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Menu Discount Price
                </label>
                <input
                  type="number"
                  name="dprice"
                  placeholder="Enter menu discount price"
                  value={menuData.dprice}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0);
                    setMenuData((prev) => ({
                      ...prev,
                      dprice: value,
                    }));
                  }}
                  onBlur={() => {
                    setMenuData((prev) => ({
                      ...prev,
                      dprice: Math.max(0, parseFloat(prev.dprice) || 0),
                    }));
                  }}
                  className={`mt-1 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 ${
                    parseFloat(menuData.dprice) > parseFloat(menuData.price) ||
                    parseFloat(menuData.dprice) < 0
                      ? "border-red-500"
                      : "border-black"
                  }`}
                />
                {parseFloat(menuData.dprice) > parseFloat(menuData.price) && (
                  <p className="text-red-500 text-sm">
                    Discount price cannot exceed the menu price.
                  </p>
                )}
                {parseFloat(menuData.dprice) < 0 && (
                  <p className="text-red-500 text-sm">
                    Discount price must be zero or greater.
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Allergies
                </label>
                <input
                  type="text"
                  name="allergies"
                  value={menuData.allergies}
                  onChange={handleChange}
                  placeholder="Enter Allergies"
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Vegan
                </label>
                <input
                  type="checkbox"
                  name="baigan"
                  checked={menuData.baigan === "Yes"}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: "baigan",
                        value: e.target.checked ? "Yes" : "No",
                      },
                    })
                  }
                  className="mt-1 h-5 w-5 border border-black rounded shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">
                  Is this Vegan?
                </span>
              </div>

              {/* Description */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="description"
                  value={menuData.description}
                  onChange={handleChange}
                  placeholder="Enter description"
                  className="mt-1 block w-full border border-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3"
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Homemade
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="checkbox"
                    name="menuType"
                    checked={menuData.menuType === "Yes"}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "menuType",
                          value: e.target.checked ? "Yes" : "No",
                        },
                      })
                    }
                    className="h-4 w-4 text-blue-600 border-black rounded focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    Is this homemade?
                  </span>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-3 py-3 rounded-md mr-3 text-lg"
                  onClick={() => setAddMenuModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-600"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isEditMenuModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-sm sm:max-w-md md:max-w-sm lg:max-w-md xl:max-w-lg p-10 overflow-y-auto max-h-[90vh]">
            <h2 className="text-2xl font-bold mb-6 text-center">Edit Menu</h2>
            <form>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  value={editmenuData.name}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  value={editmenuData.price}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0); // Allow empty values temporarily
                    editsetMenuData((prev) => ({
                      ...prev,
                      price: value,
                    }));
                  }}
                  onBlur={() => {
                    // Validate on blur to ensure no empty or invalid values
                    editsetMenuData((prev) => ({
                      ...prev,
                      price:
                        prev.price === ""
                          ? 0
                          : Math.max(0, parseFloat(prev.price) || 0),
                    }));
                  }}
                  min="0"
                  className={`w-full border p-3 rounded focus:outline-none focus:ring-2 ${
                    parseFloat(editmenuData.price) <= 0 &&
                    editmenuData.price !== ""
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {parseFloat(editmenuData.price) <= 0 &&
                  editmenuData.price !== "" && (
                    <p className="text-red-500 text-sm mt-1">
                      Price must be a positive value greater than zero.
                    </p>
                  )}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Discount Price
                </label>
                <input
                  type="number"
                  name="dprice"
                  value={editmenuData.dprice}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0); // Allow empty values temporarily
                    editsetMenuData((prev) => ({
                      ...prev,
                      dprice: value,
                    }));
                  }}
                  onBlur={() => {
                    // Validate on blur to ensure no empty or invalid values
                    editsetMenuData((prev) => ({
                      ...prev,
                      dprice:
                        prev.dprice === ""
                          ? 0
                          : Math.min(
                              Math.max(0, parseFloat(prev.dprice) || 0),
                              parseFloat(prev.price) || 0
                            ),
                    }));
                  }}
                  min="0"
                  className={`w-full border p-3 rounded focus:outline-none focus:ring-2 ${
                    parseFloat(editmenuData.dprice) >
                      parseFloat(editmenuData.price) ||
                    editmenuData.dprice === ""
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {parseFloat(editmenuData.dprice) >
                  parseFloat(editmenuData.price) && (
                  <p className="text-red-500 text-sm mt-1">
                    Discount price cannot exceed the main price.
                  </p>
                )}
                {parseFloat(editmenuData.dprice) < 0 && (
                  <p className="text-red-500 text-sm mt-1">
                    Discount price must be zero or greater.
                  </p>
                )}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Allergies
                </label>
                <input
                  type="text"
                  name="allergies"
                  value={editmenuData.allergies}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Vegan
                </label>
                <input
                  type="checkbox"
                  name="baigan"
                  checked={editmenuData.baigan === "Yes"}
                  onChange={(e) =>
                    handleEditChange({
                      target: {
                        name: "baigan",
                        value: e.target.checked ? "Yes" : "No",
                      },
                    })
                  }
                  className="h-5 w-5 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
                <span className="ml-2 text-sm text-gray-600">
                  Is this Vegan?
                </span>
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  value={editmenuData.description}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Homemade
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="checkbox"
                    name="menuType"
                    checked={editmenuData.menuType === "Yes"}
                    onChange={(e) =>
                      handleEditChange({
                        target: {
                          name: "menuType",
                          value: e.target.checked ? "Yes" : "No",
                        },
                      })
                    }
                    className="h-4 w-4 text-blue-600 border-black rounded focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    Is this homemade?
                  </span>
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Category
                </label>
                <select
                  name="category"
                  value={editmenuData.category} // Bind value to editmenuData.category
                  onChange={(e) => {
                    // Ensure onChange properly updates the state
                    const selectedValue = e.target.value;
                    handleEditChange({
                      target: { name: "category", value: selectedValue },
                    });
                  }}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  <option value="" disabled>
                    Select category
                  </option>
                  {category.map((cat, index) => (
                    <option key={index} value={cat.name}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setEditMenuModalOpen(false)}
                  className="bg-gray-500 text-white px-6 py-3 rounded-md mr-3 text-lg"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleEditSubmit}
                  className="px-4 py-2 bg-black text-white rounded-md mr-2"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {addPreviousMenuModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-1/2 max-w-lg">
            <h2 className="text-2xl font-bold mb-6">Add/Edit Previous Menu</h2>
            <form onSubmit={handleSubmitPrevMenu}>
              {/* Date Field */}
              <div className="mb-6">
                <label className="block text-gray-700 text-lg">Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="w-full border p-3 rounded-md text-lg"
                />
              </div>

              {/* Menu Selection */}
              <div className="mb-6">
                <label className="block text-gray-700 text-lg">
                  Select Menu
                </label>
                <select
                  name="menuType"
                  onChange={handleInputChange}
                  className="w-full border p-3 rounded-md text-lg"
                >
                  <option value="new">Add Previous Menu</option>
                  <option value="exist">Add with existing menu</option>
                </select>
              </div>

              {/* Buttons */}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setAddPreviousMenuModalOpen(false)}
                  className="bg-gray-500 text-white px-6 py-3 rounded-md mr-3 text-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-black text-white rounded-md mr-2"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-20"
        overlayClassName="bg-black bg-opacity-50 fixed inset-0 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold mb-4">Upload Weekly Menu</h2>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          className="mb-4"
        />
        <button
          className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
          onClick={handleSubmitWeekLy}
        >
          Submit
        </button>
        <button
          className="px-4 py-2 bg-black text-white rounded-md mr-2"
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </button>
      </Modal>
    </div>
  );
};

export default MenuDescription;
