import React from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Redirect = ({ src, alt, text, id, externalPath }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (externalPath) {
      navigate(externalPath); // Navigate to another page if externalPath is provided
    }
  };

  return externalPath ? (
    <div
      onClick={handleRedirect}
      className="flex flex-col justify-start items-center w-full py-0 bg-light border-0 md:border-2 border-dark cursor-pointer gap-5 transition-all duration-300 ease-in-out hover:bg-lightDarker active:bg-lightDarker md:w-1/3 md:gap-12 sm:py-[5vh] md:py-[10vh] group"
    >
      <h2 className="subtitle text-dark mt-5 group-hover:scale-110 transition duration-300 ease-in-out">
        {text}
      </h2>
      <div className="flex justify-center w-1/2">
        <img
          src={src}
          alt={alt}
          className="mb-10 object-contain group-hover:scale-110 transition duration-300 ease-in-out"
        />
      </div>
    </div>
  ) : (
    <Link
      to={id}
      smooth={true}
      duration={500}
      className="flex flex-col justify-start items-center w-full py-0 bg-light border-0 md:border-2 border-dark cursor-pointer gap-5 transition-all duration-300 ease-in-out hover:bg-lightDarker active:bg-lightDarker md:w-1/3 md:gap-12 sm:py-[5vh] md:py-[10vh] group"
    >
      <h2 className="subtitle text-dark mt-5 group-hover:scale-110 transition duration-300 ease-in-out">
        {text}
      </h2>
      <div className="flex justify-center w-1/2">
        <img
          src={src}
          alt={alt}
          className="mb-10 object-contain group-hover:scale-110 transition duration-300 ease-in-out"
        />
      </div>
    </Link>
  );
};

export default Redirect;
