import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Highlight from "../Highlight/Highlight";
import { Images } from "../../assets";

const Carousel = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Tailwind mobile breakpoint
    };
    handleResize(); // Set initial value

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Data for the items
  const items = [
    {
      src: Images.PIZZA_ICON,
      alt: "Icône de pizza",
      text: "Authentiques, savoureuses, aux saveurs italiennes incomparables",
      to: "Pizza",
    },
    {
      src: Images.DRINK_ICON,
      alt: "Icône de verre de vin",
      text: "Vins fins et cocktails pour sublimer chaque bouchée",
      to: "Bevande E Caffè",
    },
    {
      src: Images.ICE_CREAM_ICON,
      alt: "Icône de glace",
      text: "Douceur italienne : tiramisus, glaces crémeuses et plus",
      to: "Dolci",
    },
  ];

  // Slider settings for mobile
  const mobileSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  if (isMobile) {
    return (
      <Slider {...mobileSettings} className="mb-8 overflow-x-hidden">
        {items.map((item, index) => (
          <Highlight key={index} {...item} />
        ))}
      </Slider>
    );
  }

  // Desktop layout using flex for even spacing
  return (
    <div className="flex justify-around gap-5 w-full mb-[3em]">
      {items.map((item, index) => (
        <Highlight key={index} {...item} />
      ))}
    </div>
  );
};

export default Carousel;
