import React, { useState } from "react"; // Import necessary React hooks
import { useParams, useNavigate } from "react-router-dom"; // Import hooks for routing
import { toast } from "react-toastify"; // Import toast for notifications
import axiosInstance from "../api"; // Import axios instance for API requests

const ResetPassword = () => {
  const { token } = useParams(); // Retrieve token from URL parameters
  const navigate = useNavigate(); // Initialize navigation hook
  const [password, setPassword] = useState(""); // State for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [loading, setLoading] = useState(false); // State to manage loading status

  // Function to handle password reset
  const handleResetPassword = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (password !== confirmPassword) { // Check if passwords match
      toast.error("Passwords do not match!"); // Show error if passwords do not match
      return; // Exit function
    }
    setLoading(true); // Set loading state to true while making request
    try {
      const response = await axiosInstance.post( // Send reset password request
        `/api/auth/reset-password/${token}`, // API endpoint with token in URL
        { password }, // Send password in the request body
        { headers: { "Content-Type": "application/json" } } // Set content type
      );
      console.log(response); // Log the response from the server
      setLoading(false); // Set loading state to false after request completes
      if (response.data.success) { // Check if reset was successful
        toast.success("Password reset successfully!"); // Show success message
        navigate("/login"); // Redirect to login page after success
      } else {
        toast.error(response.data.message || "Failed to reset password."); // Show error message if reset fails
      }
    } catch (error) {
      setLoading(false); // Set loading state to false if an error occurs
      toast.error(
        error.response?.data?.message || // Show error message from response, or default error message
          "Something went wrong. Please try again."
      );
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100"> {/* Center the form on the screen */}
      <div className="bg-white p-8 rounded shadow-md w-96"> {/* Container for the form */}
        <h2 className="text-2xl font-semibold mb-6">Reset Password</h2> {/* Heading for the form */}
        <form onSubmit={handleResetPassword}> {/* Form submission handler */}
          <div className="mb-4"> {/* Password input container */}
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Update password state on change
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              placeholder="Enter new password"
              required
            />
          </div>
          <div className="mb-4"> {/* Confirm password input container */}
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)} // Update confirm password state on change
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              placeholder="Confirm new password"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-200"
          >
            {loading ? "Resetting..." : "Reset Password"} {/* Show loading text if loading is true */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword; // Export the ResetPassword component
