export const Images = {
  HEADER_BG_IMAGE: require("./images/Background.webp"),
  ROUNDED_TITLE: require("./images/rounded-title.svg").default,

  DRINK_ICON: require("./images/icons/drink.svg").default,
  ICE_CREAM_ICON: require("./images/icons/ice-cream.svg").default,
  PIZZA_ICON: require("./images/icons/pizza-slice.svg").default,

  NOODLES: require("./images/pates.png"),
  PUDDING: require("./images/tiramisu.png"),
  SUSHE: require("./images/tomates.png"),

  PIZZA:require('./images/Pizza.png'),
  LOCATION:require('./images/icons/location.svg').default,

  CONTACT: require("./images/contact.svg").default,

  INSTAGRAM_ICON: require("./images/icons/instagram.svg").default,
  FACEBOOK_ICON: require("./images/icons/facebook.svg").default,
  LINKEDIN_ICON: require("./images/icons/linkedin.svg").default,

  MAIN_LOGO: require("./images/logo/logo.png"),
  BG_MAIN_LOGO: require("./images/logo/bg-logo.png"),

  HOME: require("./icons/home.svg").default,
  MENU: require("./icons/menu.svg").default,
  ABOUT: require("./icons/about.svg").default,
  CONTACT: require("./icons/contact.svg").default,
};
