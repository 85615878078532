import React from "react";
import { Link as RouterLink } from "react-router-dom";

const Highlight = ({ src, alt, text, to }) => {
  return (
    <RouterLink
      to={`/menu#${to}`}
      className="flex flex-col items-center md:w-1/4 flex-shrink-0 cursor-pointer"
    >
      <img src={src} alt={alt} />
      <p className="w-2/3 md:w-1/2 mt-3">{text}</p>
    </RouterLink>
  );
};

export default Highlight;
