import React, { useState, useEffect, useRef } from "react";
import { Images } from "../assets";
import MenuTitlesCard from "../components/MenuTitlesCard/MenuTitlesCard";
import MenuCard from "../components/MenuCard/MenuCard";

const Menu = () => {
  const [menus, setMenus] = useState([]); // Store menu data
  const [categories, setCategories] = useState([]);
  const [suggestions, setSuggestions] = useState([])
  const [dishes, setDishes] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(true); // Track loading state
  const [activeIndex, setActiveIndex] = useState(null); // Track clicked index for categories

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const req = await fetch(`${process.env.REACT_APP_SERVER}/api/menu/get-menu`);
        const data = await req.json();
        setMenus(data);
      } catch (error) {
        console.error("Error fetching menus:", error);
      } finally {
        setLoadingMenu(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const req = await fetch(
          `${process.env.REACT_APP_SERVER}/api/category/get-category`
        );
        const data = await req.json();
        const categoryNames = data.data.map((category) => category.name);
        setCategories(categoryNames);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };


    const fetchSuggestions = async () => {
      try {
        const req = await fetch(
          `${process.env.REACT_APP_SERVER}/api/chef/get-chef`
        );
        const data = await req.json();
        setSuggestions(data.data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };
    
    const getFormattedDate = () => {
      // Function to get formatted current date
      const date = new Date(); // Create a new date object
      const year = date.getFullYear(); // Get the year
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month, pad with zero if single digit
      const day = date.getDate().toString().padStart(2, "0"); // Get the day, pad with zero if single digit
      return `${year}-${month}-${day}`; // Return formatted date as a string
    };

    const fetchDishes = async () => {
      try {
        const req = await fetch(
          `${process.env.REACT_APP_SERVER}/api/dish/get-dish-date/${getFormattedDate()}` // Fetch today's menu by date
        );
        const data = await req.json();
        setDishes(data[0].items);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    }

    fetchMenus();
    fetchCategories();
    fetchSuggestions();
    fetchDishes();
  }, []);

  // Refs for each category
  const categoryRefs = useRef(categories.map(() => React.createRef()));

  // Handle categories title click
  const handleCardClick = (index) => {
    // Toggle active status
    setActiveIndex(index === activeIndex ? null : index);

    // Scroll to the corresponding category
    if (categoryRefs.current[index]) {
      categoryRefs.current[index].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="min-h-screen bg-light">
      {/* Header */}
      <section className="bg-dark flex justify-center items-center py-3 md:py-8">
        <a href="/">
          <img
            src={Images.MAIN_LOGO}
            alt="Logo Cavallo Bianco"
            className="w-[7em] lg:w-[10em]"
          />
        </a>
      </section>

      <section className="bg-light">
        {/* Title */}
        <h1 className="uppercase text-dark text-6xl font-title tracking-widest py-[8vh]">
          Menu
        </h1>

        <div className="grid grid-cols-2">
          {/* Dishes */}
          <MenuTitlesCard size="small" text="Plats et dessert du jour" />
          <div className="col-span-2 mt-8 order-0 lg:col-span-1 lg:order-3">
            {loadingMenu ? (
              <p>Chargement des menus...</p>
            ) : dishes.length > 0 ? (
              dishes.map((menu, index) => (
                <MenuCard
                  key={index}
                  {...menu}
                  isLast={index === dishes.length - 1} // Check if it's the last element
                />
              ))
            ) : (
              <p className="mb-8">Aucun plat disponible</p>
            )}
          </div>

          {/* Suggestions */}
          <MenuTitlesCard size="small" text="Suggestions du chef" />
          <div className="col-span-2 mt-8 order-0 lg:col-span-1 lg:order-3">
            {suggestions.length > 0 ? (
              suggestions.map((suggestion, index) => (
                <MenuCard
                  key={index}
                  home={suggestion.category}
                  {...suggestion}
                  isLast={index === suggestions.length - 1} // Check if it's the last element
                />
              ))
            ) : (
              <p>Aucune suggestion disponible</p>
            )}
          </div>
        </div>
      </section>

      {/* Categories array above */}
      <section className="bg-light grid grid-cols-1">
        {/* Table Wrapper */}
        <div className="sticky top-0 bg-light z-10">
          <div className="mx-[5vw] lg:flex justify-center">
            <div className="overflow-x-auto w-full">
              <table className="w-[90vw] border-collapse mb-2 lg:table-fixed">
                <tbody>
                  <tr>
                    {categories.map((category, index) => (
                      <td
                        key={index}
                        className={`p-3 border-2 border-dark uppercase tracking-widest cursor-pointer md:p-5 ${
                          activeIndex === index
                            ? "bg-dark text-light"
                            : "bg-transparent text-dark"
                        }`}
                        onClick={() => handleCardClick(index)}
                      >
                        {category}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Categories big titles */}
        {categories.map((category, index) => (
          <div
            key={index}
            ref={categoryRefs.current[index]} // Attach ref to each category
          >
            <MenuTitlesCard
              size="big"
              text={category}
              onClick={() => handleCardClick(index)}
              isActive={activeIndex === index}
              dishes={menus.filter(
                (dish) => dish.category.toLowerCase() === category.toLowerCase()
              )}
            />
          </div>
        ))}
      </section>
    </div>
  );
};

export default Menu;
