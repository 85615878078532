import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import BurgerMenu from "./components/BurgerMenu/BurgerMenu.js";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import "./App.css";
import Login from "./pages/Login"; // Importing Login component
import Dashboard from "./pages/Dashboard"; // Importing Dashboard component
import AddUser from "./pages/AddUser"; // Importing AddUser component
import PasswordReset from "./pages/PasswordReset"; // Importing PasswordReset component
import Menudetails from "./pages/Menudetails"; // Importing Menudetails component
import MenuDescription from "./pages/MenuDescription"; // Importing MenuDescription component
import UserList from "./pages/UserList"; // Importing UserList component
import ChefSuggestion from "./pages/ChefSuggesstin"; // Importing ChefSuggestion component
import ResetPasswordPage from "./pages/ResetPasswordPage"; // Importing ResetPasswordPage component
import ProfilePage from "./pages/Profile"; // Importing ProfilePage component
import AdminContact from "./pages/AdminContact"; // Importing AdminContact component
import Categorypage from "./pages/CategoryPage"; // Importing Categorypage component
import NotFoundPage from "./pages/NotFound"; // Importing NotFoundPage component for 404 page
import DishPage from "./pages/DishPae"; // Importing DishPage component
import DishDescription from "./pages/DishDescription"; // Importing DishDescription component

const App = () => {
  const location = useLocation();

  // Specify the paths where the header should not be displayed
  const hideHeaderRoutes = ["/menu", "/login", "/dashboard", "/add-user", "/user-list", "/add-new-password", "/reset-password", "/menu-details", "/menu-description", "/dish-description", "/dish-description/.+", "/chef", "/profile", "/contact", "/category", "/dish"];
  const hideFooterRoutes = ["/login", "/dashboard", "/add-user", "/user-list", "/add-new-password", "/reset-password", "/menu-details", "/menu-description", "/dish-description", "/dish-description/.+", "/chef", "/profile", "/contact", "/category", "/dish"];
  var hideHeader = false;
  var hideFooter = false;


  hideHeaderRoutes.forEach((route) => {
    if (RegExp(route).test(location.pathname)) {
      hideHeader = true;
    }
  });

  hideFooterRoutes.forEach((route) => {
    if (RegExp(route).test(location.pathname)) {
      hideFooter = true;
    }
  });

  return (
    <div className="App">
      <BurgerMenu />
      {/* Conditionally render the header */}
      {!hideHeader && <Header />}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/login" element={<Login />} /> {/* Route for Login */}
          <Route path="/dashboard" element={<Dashboard />} /> {/* Route for Dashboard */}
          <Route path="/add-user" element={<AddUser />} /> {/* Route for AddUser */}
          <Route path="/user-list" element={<UserList />} /> {/* Route for UserList */}
          <Route path="/add-new-password" element={<PasswordReset />} /> {/* Route for PasswordReset */}
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} /> {/* Route for ResetPasswordPage */}
          <Route path="/menu-details" element={<Menudetails />} /> {/* Route for Menudetails */}
          <Route path="/menu-description" element={<MenuDescription />} /> {/* Route for MenuDescription */}
          <Route path="/dish-description/:date" element={<DishDescription />} /> {/* Route for DishDescription */}
          <Route path="/chef" element={<ChefSuggestion />} /> {/* Route for ChefSuggestion */}
          <Route path="/profile" element={<ProfilePage />} /> {/* Route for ProfilePage */}
          <Route path="/contact" element={<AdminContact />} /> {/* Route for AdminContact */}
          <Route path="/category" element={<Categorypage />} /> {/* Route for Categorypage */}
          <Route path="/dish" element={<DishPage />} /> {/* Route for DishPage */}
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 NotFoundPage */}
        </Routes>
      </main>
      {/* Conditionally render the footer */}
      {!hideFooter && <Footer />}
    </div>
  );
};

// Wrap App in Router for useLocation to work
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
