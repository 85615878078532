// Importing React from 'react' to use React features
import React from 'react';

// ConfirmationModal component takes in 'isOpen', 'onClose', and 'onConfirm' as props
const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {

  // If the modal is not open, return null and don't render anything
  if (!isOpen) return null;

  // Modal structure and content
  return (
    // Full-screen overlay with black background and semi-opacity for the modal
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      
      {/* Modal box with padding, rounded corners, shadow, and max-width constraint */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-sm">
        
        {/* Modal heading */}
        <h2 className="text-xl font-semibold text-center mb-4">Are you sure?</h2>
        
        {/* Modal content explaining the action */}
        <p className="text-center text-gray-700 dark:text-gray-300 mb-6">
          This will delete all contacts permanently. This action cannot be undone.
        </p>
        
        {/* Container for the action buttons */}
        <div className="flex justify-center gap-4">
          
          {/* 'Yes, Delete All' button that triggers the 'onConfirm' callback */}
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-full hover:bg-red-500 transition-all"
          >
            Yes, Delete All
          </button>
          
          {/* 'Cancel' button that triggers the 'onClose' callback */}
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded-full hover:bg-gray-500 transition-all"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// Export the ConfirmationModal component to be used in other parts of the app
export default ConfirmationModal;
