import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Sidebar/Sidebar";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosInstance from "../api";

const ChefSuggestion = () => {
  // Using 'useNavigate' to handle navigation in the app
  const navigate = useNavigate();

  // State to hold the suggestions data from the API
  const [suggestions, setSuggestions] = useState([]);

  // State to manage loading state (loading spinner or indicator)
  const [loading, setLoading] = useState(true);

  // State to control the visibility of the modal (for adding/editing chef suggestions)
  const [modal, isModal] = useState(false);

  // Function to validate the user's token by sending it to the backend
  const fetchDataValid = async () => {
    const token = localStorage.getItem("token"); // Retrieve the token from local storage

    // If no token is found, navigate to login page
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      // Make API request to validate the token
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Sending token in the header for validation
          },
        }
      );

      // If token is valid, do nothing, else navigate to login page
      if (response.data.isValid) {
        return;
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during token validation:", error); // Log errors to the console
      navigate("/login"); // If an error occurs, redirect to login
    }
  };

  // Effect hook to validate token when the component mounts
  useEffect(() => {
    fetchDataValid();
  }, []); // Empty dependency array to run only on mount

  // Function to fetch suggestions from the backend API
  const fetchSuggestions = async () => {
    try {
      // Making API call to fetch chef suggestions
      const response = await axiosInstance.get(`/api/chef/get-chef`);
      setSuggestions(response.data.data); // Set the fetched suggestions in state
    } catch (error) {
      console.error("Error fetching suggestions:", error); // Log errors to the console
    } finally {
      setLoading(false); // Set loading to false after the fetch is complete
    }
  };

  // Function to handle deletion of a suggestion
  const deleteSuggestion = async (id) => {
    try {
      // Making API call to delete the suggestion by its ID
      await axiosInstance.delete(`/api/chef/delete/${id}`);
      setSuggestions(suggestions.filter((suggestion) => suggestion._id !== id)); // Remove deleted suggestion from state
    } catch (error) {
      console.error("Error deleting suggestion:", error); // Log errors to the console
    }
  };

  // State for the form data to add or edit a chef suggestion
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "No", // Default value for category
    price: "",
    allergies: "",
  });

  // Function to handle form input changes and update the formData state
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Update formData with the input field values
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? "Yes" : "No") : value,
    });
  };

  // Function to handle form submission when adding a new chef suggestion
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading state to true while the form is submitting
    try {
      // Making API request to add the new chef suggestion
      const response = await axiosInstance.post(`/api/chef/add-chef`, formData);
      toast.success("Chef suggestion added successfully!"); // Show success toast
      fetchSuggestions(); // Fetch updated list of suggestions
      isModal(false); // Close the modal after successful submission
      setFormData({
        name: "",
        description: "",
        category: "",
        allergies: "",
      }); // Reset form data
    } catch (error) {
      toast.error(error.response.data.message); // Show error toast
      console.error("Error adding chef suggestion:", error); // Log errors to the console
    } finally {
      setLoading(false); // Set loading to false after submission attempt
    }
  };

  // Effect hook to fetch the list of suggestions when the component mounts
  useEffect(() => {
    fetchSuggestions();
  }, []); // Empty dependency array to run only on mount

  // State for managing the visibility of the edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);

  // State for the selected suggestion to edit
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  // State for managing the form data of the edit modal
  const [eidtformData, editsetFormData] = useState({
    name: "",
    category: "",
    description: "",
    price: "",
    isAvailable: true, // Default value for availability
    allergies: "",
  });

  // Function to open the edit modal with pre-filled data
  const openEditModal = (suggestion) => {
    setSelectedSuggestion(suggestion); // Set the selected suggestion to be edited
    editsetFormData({
      name: suggestion.name,
      description: suggestion.description,
      category: suggestion.category,
      price: suggestion.price,
      isAvailable: suggestion.isAvailable,
      allergies: suggestion.allergies,
    }); // Pre-fill form with existing data
    setEditModalOpen(true); // Open the edit modal
  };

  // Function to handle input changes in the edit form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    editsetFormData({
      ...eidtformData,
      [name]: value,
    });
  };

  // Function to handle form submission when editing a chef suggestion
  const handleSubmitEdit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      // Making API request to update the chef suggestion by its ID
      await axiosInstance.put(
        `/api/chef/update/${selectedSuggestion._id}`,
        eidtformData
      );
      fetchSuggestions(); // Fetch updated list of suggestions
      setEditModalOpen(false); // Close the edit modal after successful submission
      toast.success("Updated Successfully"); // Show success toast
    } catch (error) {
      toast.error("Internal Server Error...."); // Show error toast
      console.error("Error updating suggestion:", error); // Log errors to the console
    }
  };

  // State for managing the current page of the paginated list
  const [currentPage, setCurrentPage] = useState(1);

  // Number of items to display per page
  const itemsPerPage = 5;

  // Calculate the total number of pages based on the number of suggestions and items per page
  const totalPages = Math.ceil(suggestions.length / itemsPerPage);

  // Get the data for the current page (pagination)
  const currentSuggestions = suggestions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Function to handle page changes when the user clicks on pagination controls
  const changePage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber); // Update the current page number
    }
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14">
          <div className="flex justify-end mb-4">
            <button
              className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
              onClick={() => isModal(true)}
            >
              Add Chef Suggestion
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full text-sm text-left text-black-500 dark:text-black-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-300 dark:text-black-400">
                <tr>
                  <th className="px-6 py-3">Name</th>
                  <th className="px-6 py-3">Description</th>
                  <th className="px-6 py-3">Price</th>
                  <th className="px-6 py-3">Available</th>
                  <th className="px-6 py-3">Home Made</th>
                  <th className="px-6 py-3">Allergies</th>
                  <th className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentSuggestions && currentSuggestions.length > 0 ? (
                  currentSuggestions.map((suggestion) => (
                    <tr
                      key={suggestion._id}
                      className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-200"
                    >
                      <td className="px-6 py-3">{suggestion.name}</td>
                      <td className="px-6 py-3">{suggestion.description}</td>

                      <td className="px-6 py-3">{suggestion.price}</td>
                      <td className="px-6 py-3">
                        {suggestion.isAvailable ? (
                          <span className="text-green-500">✔</span>
                        ) : (
                          <span className="text-red-500">✘</span>
                        )}
                      </td>
                      <td className="px-6 py-3">{suggestion.category}</td>
                      <td className="px-6 py-3">{suggestion.allergies}</td>
                      <td className="px-6 py-3">
                        <div className="flex space-x-2">
                          <FaTrash
                            className="h-6 w-6 text-red-500 cursor-pointer"
                            onClick={() => deleteSuggestion(suggestion._id)}
                          />
                          <FaEdit
                            className="h-6 w-6 text-blue-500 cursor-pointer"
                            onClick={() => openEditModal(suggestion)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center px-6 py-3 text-gray-500"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-center items-center space-x-2 mt-4">
              <button
                className={`px-4 py-2 text-sm rounded ${
                  currentPage === 1
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-500 text-white"
                }`}
                disabled={currentPage === 1}
                onClick={() => changePage(currentPage - 1)}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  className={`px-4 py-2 text-sm rounded ${
                    currentPage === i + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => changePage(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
              <button
                className={`px-4 py-2 text-sm rounded ${
                  currentPage === totalPages
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-gray-900 text-white"
                }`}
                disabled={currentPage === totalPages}
                onClick={() => changePage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>

          {modal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-96 p-6">
                <h2 className="text-xl font-bold mb-4">Add Chef Suggestion</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Description
                    </label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      rows="3"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Allergies
                    </label>
                    <textarea
                      name="allergies"
                      value={formData.allergies}
                      onChange={handleChange}
                      rows="3"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Home Made
                    </label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        name="category"
                        checked={formData.category === "Yes"}
                        onChange={handleChange}
                        className="focus:outline-none focus:ring focus:ring-blue-300"
                      />
                      <span>{formData.category === "Yes" ? "Yes" : "No"}</span>
                    </div>
                    <p className="text-sm text-gray-500 mt-2">
                      Is it Home Made?
                    </p>
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Price
                    </label>
                    <input
                      type="number"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                      required
                    />
                  </div>

                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => isModal(false)}
                      className="px-4 py-2 bg-black text-white rounded-md mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {editModalOpen && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                <h2 className="text-xl font-bold mb-4">Edit Chef Suggestion</h2>
                <form onSubmit={handleSubmitEdit}>
                  <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={eidtformData.name}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Home Made
                    </label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        name="category"
                        checked={formData.category === "Yes"}
                        onChange={handleChange}
                        className="focus:outline-none focus:ring focus:ring-blue-300"
                      />
                      <span>{formData.category === "Yes" ? "Yes" : "No"}</span>
                    </div>
                    <p className="text-sm text-gray-500 mt-2">
                      Is it Home Made?
                    </p>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Description</label>
                    <textarea
                      type="text"
                      name="description"
                      value={eidtformData.description}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Allergies</label>
                    <input
                      type="text"
                      name="allergies"
                      value={eidtformData.allergies}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Price</label>
                    <input
                      type="text"
                      name="price"
                      value={eidtformData.price}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="inline-flex items-center text-gray-700">
                      <input
                        type="checkbox"
                        name="isAvailable"
                        checked={eidtformData.isAvailable}
                        onChange={() =>
                          editsetFormData({
                            ...eidtformData,
                            isAvailable: !eidtformData.isAvailable,
                          })
                        }
                        className="mr-2"
                      />
                      Available
                    </label>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => setEditModalOpen(false)}
                      className="px-4 py-2 bg-black text-white rounded-md mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
                <button
                  onClick={() => setEditModalOpen(false)}
                  className="absolute top-2 right-2 text-gray-600"
                >
                  X
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChefSuggestion;
