import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api";

const AddUser = () => {
  // Initializing state to hold form data (name, email, password, phone)
  const [formData, setFormData] = useState({
    name: "", // Name field
    email: "", // Email field
    password: "", // Password field
    phone: "", // Phone number field
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target; // Destructuring name and value from the event target
    if (name === "phone") {
      // If the input field is the phone number
      const formattedValue = value.replace(/\D/g, "").slice(0, 10); // Remove non-digit characters and limit the phone number to 10 digits
      setFormData((prevData) => ({
        ...prevData, // Spread the previous form data
        [name]: formattedValue, // Update the phone number field
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData, // Spread the previous form data
        [name]: value, // Update the other field (name, email, password)
      }));
    }
  };

  // Using the navigate hook to redirect the user
  const navigate = useNavigate();

  // Function to validate the token from localStorage
  const fetchDataValid = async () => {
    const token = localStorage.getItem("token"); // Retrieve token from localStorage

    if (!token) {
      // If no token is found, redirect to login page
      navigate("/login");
      return;
    }

    try {
      // Make an API call to validate the token
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`, // API endpoint for token validation
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
        }
      );

      if (response.data.isValid) {
        // If the token is valid
        if (response.data.isAdmin) {
          // If the user is an admin, do nothing
          return;
        } else {
          navigate("/dashboard"); // If not an admin, navigate to the dashboard
        }
      } else {
        navigate("/login"); // If the token is invalid, redirect to login page
      }
    } catch (error) {
      // If an error occurs during the API call
      console.error("Error during token validation:", error); // Log the error
      navigate("/login"); // Redirect to login page
    }
  };

  // useEffect hook to run token validation when the component is mounted
  useEffect(() => {
    fetchDataValid(); // Call the token validation function
  }, []); // Empty dependency array ensures the effect runs once on component mount

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Check if the phone number is valid (at least 10 digits)
    if (!formData.phone.length >= 10) {
      toast.error("Please enter a valid 10-digit phone number."); // Show error if phone number is invalid
      return;
    }

    try {
      // Make an API call to register the user with the form data
      const response = await axiosInstance.post("/api/auth/register", formData);
      toast.success(response.data.message); // Show success message from the server response
      setFormData({ name: "", email: "", password: "", phone: "" }); // Reset the form data after successful submission
    } catch (error) {
      toast.error("Failed to add user. Please try again."); // Show error message if the registration fails
    }
  };

  return (
    <>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14">
          <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-white-800 dark:border-white-700 max-w-4xl mx-auto">
            <h2 className="text-3xl font-semibold text-gray-800 dark:text-black mb-8 text-center">
              Add New User
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name Field */}
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-sm font-medium text-black-700 dark:text-black mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-black-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-white-800 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-md"
                  placeholder="Enter user's name"
                  required
                />
              </div>

              {/* Email Field */}
              <div className="flex flex-col">
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-black-700 dark:text-black mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-black-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-white-800 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-md"
                  placeholder="Enter user's email"
                  required
                />
              </div>

              {/* Password Field */}
              <div className="flex flex-col">
                <label
                  htmlFor="password"
                  className="text-sm font-medium text-black-700 dark:text-black mb-2"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-black-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-white-800 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-md"
                  placeholder="Enter a password"
                  required
                />
              </div>

              {/* Phone Field */}
              <div className="flex flex-col">
                <label
                  htmlFor="phone"
                  className="text-sm font-medium text-black-700 dark:text-black mb-2"
                >
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Allow only digits and restrict to a maximum of 10 characters
                    const formattedValue = inputValue
                      .replace(/\D/g, "")
                      .slice(0, 10);
                    setFormData((prevData) => ({
                      ...prevData,
                      phone: formattedValue,
                    }));
                  }}
                  className="bg-gray-50 border border-gray-300 text-black-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-white-800 dark:border-white-600 dark:placeholder-white-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-md"
                  placeholder="Enter user's phone number"
                  required
                />
              </div>

              {/* Submit Button */}
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-6 py-3 w-full sm:w-auto shadow-md transition duration-200 ease-in-out"
                >
                  Add User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
