const MenuCard = ({ name, baigan, price, description, isLast, home, dprice, allergies }) => {
  return (
    <div className="ml-[5vw]">
      <div className="flex flex-col items-start text-start gap-0 text-dark md:gap-2">
        <p className="text-2xl font-bold tracking-0.2em">
          {name}{" "}
          {baigan === "Yes" ? <span className="text-sm border border-[#713D11] px-2 py-2 rounded m-1">Vegan</span> : ""}
          {home === "Yes" ? <span className="text-sm border border-[#713D11] px-2 py-2 rounded">Home</span> : ""}
        </p>
        {dprice < price && dprice != "" && dprice != 0 && dprice != null && (
          <p className="text-2xl font-bold tracking-widest line-through">CHF {price}</p>
        )}
        <p className="text-2xl font-bold tracking-widest">CHF {dprice < price && dprice != "" && dprice != 0 && dprice != null ? dprice : price}</p>
        <p className="text-sm">{description}</p>
        {allergies && allergies !== "" && (
          <p className="text-sm">Allergies: {allergies}</p>
        )}
      </div>

      {!isLast ? (
        <>
          {/* Render this content if not the last */}
          <span className="block my-10 w-3/5 border-b-[1px] border-dark" />
        </>
      ) : (
        <>
          {/* Render this content if it's the last */}
          <span className="block my-10" />
        </>
      )}
    </div>
  );
};

export default MenuCard;
